

















































import Vue from 'vue';

export default Vue.extend({
    name: 'MartusGameSpace',
    components: {
    },
    data() {
        return {
            url: ''
        }
    },
    async created() {
        this.url = `https://app.incentivepilot.com/manobyte/539c9c29-6aa8-4fc9-a83d-2cfc66e156b4/${btoa(this.$store.state.user.name)}/${btoa(this.$store.state.user.email)}`;
        this.$store.state.routeLoaded = true;
    },
})
