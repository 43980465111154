





















































































































































































import Vue from 'vue';
import axios from 'axios';
import { mapState } from 'vuex';
import SwitchPortal from './components/SwitchPortal.vue';
import UpdateCredentialsDialog from './components/UpdateCredentialsDialog.vue';

export default Vue.extend({
    name: 'App',
    components: {
      SwitchPortal: SwitchPortal,
      UpdateCredentialsDialog: UpdateCredentialsDialog
    },
    data() {
        return {
            showMenu: true,
            hideOverflow: true,
            showEditUser: false,
            showSubMenu: false,
            showSwitchPortal: false,
            loadedInit: false,
            showUpdateCredentials: false,
            showNewFeature: false
        }
    },
    async created() {
      axios.defaults.withCredentials = true;
      window.alert = (message) => {
        this.$store.state.errorMsg = message;
        this.$store.state.showErrorMsg = true;
      }
      let sessionUrl = `${this.$store.state.apiUrl}/session`;
      if(this.$route.query.r) {
        sessionUrl += `?resetkey=${this.$route.query.r}`;
      }
      if(this.$route.query.color) {
        this.$store.state.user.portalColor = decodeURIComponent(this.$route.query.color as any);
        this.$store.state.showAdminLogIn = false;
      }
      await axios.get(sessionUrl)
        .then(resp => {
          console.log(resp.data)
          this.$store.state.user = resp.data;
          this.$store.state.signedIn = true;
          if(this.$store.state.user.isHSAdmin && !this.$store.state.user.subscriptionActive) {
            this.$router.push('/admin/payment');
          } else if(this.$store.state.user.isHSAdmin && !this.$store.state.user.name) {
            this.$router.push('/admin/settings');
          }
        })
        .catch(e => {
          this.$router.push('/login');
        })

      if(window.innerWidth < 992) {
        this.showMenu = false;
      }
      this.loadedInit = true;
      this.$store.state.routeLoaded = true;
      if(this.$route.query.r) {
        this.showUpdateCredentials = true;
      }
    },
    methods: {
        toggleMenu() {
          this.showMenu = !this.showMenu;
        },
        closeSwitchPortal() {
          this.$router.push('/');
          this.$store.state.routeLoaded = true;
          this.showSwitchPortal = false;
        },
        closeUpdateCredentials() {
          this.showUpdateCredentials = false;
        },
        hideMenuMobile() {
          if(window.innerWidth < 992) {
            this.showMenu = false;
          }
        }
    },
    computed: mapState(['routeLoaded']),
    watch: {
      $route (){
        this.$store.state.hideTopBar = false;
        this.$store.state.routeLoaded = false;
      },
      routeLoaded(newVal, oldVal) {
          if(newVal) {
            setTimeout(() => { this.hideOverflow = false; }, 600)
          } else {
            this.hideOverflow = true;
          }
      }
    }
})
